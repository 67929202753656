import {
  settings,
  SettingsTypes
} from '@powerplay/core-minigames'
import { gameSettingsState } from '@powerplay/core-minigames-ui'
import {
  getActivePinia,
  type Store
} from 'pinia'

export interface FpsDataStore {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  public setBeforeGameStartPhase(): void {

    gameSettingsState().$patch({
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType)
    })

  }

  /**
   * Resetovanie pinie
   */
  public resetPinia(): void {

    const exceptions = ['tutorialState', 'inputsState', 'mobileState', 'loadingState', 'gameSettingsState']

    // eslint-disable-next-line
    // @ts-ignore
    getActivePinia()?._s.forEach((s: Store) => {

      if (!exceptions.includes(s.$id)) s.$reset()

    })

  }

}

export const stateManager = new StateManager()
